import React, {useRef} from "react";
import { Seo } from "../components/Seo";
import * as prismicH from '@prismicio/helpers'
import { BlogFacebookIcon, BlogLinkedInIcon, BlogTwitterIcon, BlogUrlIcon } from '../utils/LegacyIcons';
import ReactTooltip from 'react-tooltip'
import { useSiteMetadata } from "../hooks/use-site-metadata";
import clsx from "clsx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import moment from "moment";

const getWordCount = (str) => {
  let matches = str.match(/\S+/g)
  return matches ? matches.length : 0
}

const BlockRenderer = ({slice}) => {
  return (
    <div className="mt-2">
      {slice.items.map((item, key) => (
        <div key={key} dangerouslySetInnerHTML={{ __html: prismicH.asHTML(item.content) }} />
      ))}
    </div>
  )
}

const ImageRenderer = ({ slice }) => {
  const { url, caption, source, alt } = {
    alt: slice.primary.image.alt ? slice.primary.image.alt : null,
    dimensions: slice.primary.image.dimensions ? slice.primary.image.dimensions : null,
    url: slice.primary.image.url,
    caption: slice.primary.caption[0] ? slice.primary.caption[0].text : null,
    source: slice.primary.source[0] ? slice.primary.source[0].text : null,
  }

  return (
    <div className="my-2 lg:my-4 w-full">
      <img src={url} alt={alt} height="auto" width="auto" />
      {caption && <caption>{caption}</caption>}
      {source && <p className="text-center text-sm opacity-50">(Source: {source})</p>}
    </div>
  )
}

const SliceRenderer = ({ slice }) => {
  switch (slice.slice_type) {
    case 'block':
      return <BlockRenderer slice={slice} />
    case 'image':
      return <ImageRenderer slice={slice} />
    default:
      return null
  }
}

const BlogSocials = ({siteUrl, slug, vertical = false}) => {
  return (
    <div className={clsx('blogSocials', vertical ? 'verticalLayout' : 'layout')}>
                  <a title="Share on Facebook"
                    target="_blank"
                    rel="noreferrer noopener"
                    href={`http://www.facebook.com/share.php?u=https://sourci.com.au/blog/${slug}`}>
                      <BlogFacebookIcon className="icon" />
                  </a>

                  <a title="Share on LinkedIn"
                    target="_blank"
                    rel="noreferrer noopener"
                    href={`http://twitter.com/share?url=${siteUrl}/blog/${slug}&hashtags=sourci`} >
                      <BlogTwitterIcon className="icon" />
                  </a>
                  <a target="_blank"
                    rel="noreferrer noopener"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${siteUrl}/blog/${slug}`} >
                    <BlogLinkedInIcon className="icon" />
                  </a>

                  <BlogUrlIcon data-tip="tooltip"
                    data-for={vertical ? 'tooltip_v' : 'tooltip_h'}
                    title="Copy link"
                    data-event="click"
                    className="icon" />
                  <ReactTooltip key={Math.random()}
                    id={vertical ? 'tooltip_v' : 'tooltip_h'}
                    effect="solid"
                    place="right"
                    afterShow={() => {
                      navigator.clipboard.writeText(`${siteUrl}/blog/${slug}`)
                        setTimeout(ReactTooltip.hide, 2500)
                    }} >
                      <span>Link copied!</span>
                  </ReactTooltip>
                </div>
  )
}

const colourResolver = (num, color = null) => {
  switch (num) {
      case 0:
          return color ? 'text-sourci-orange' : 'text-[#ff692c]'

      case 1:
          return color ? 'text-sourci-pink' : 'text-[#ed76bc]'

      case 2:
          return color ? 'text-sourci-teal' : 'text-[#00c9c9]'

      default:
          return color ? 'text-sourci-teal' : 'text-[#00c9c9]'
  }
}

const BlogTemplate = ({pageContext}) => {
  const {
    content,
    author,
    post_date,
    cover_image,
    title
  } = pageContext.data;
  let wordcount = 0;
  content.forEach(
    (slice) =>
      slice.slice_type === 'block' &&
      (wordcount += getWordCount(prismicH.asText(slice.items[0].content)))
  )
  const duration = Math.ceil(wordcount / 225);
  const {
    siteUrl 
  } = useSiteMetadata();

  const article = useRef();
  const relatedPosts = pageContext.data.related;
  return (
    <main>
      <section className="py-24">
        <div className="max-w-[768px] mx-auto px-6">
          <article ref={article}>
            <h1 className="font-black text-4xl lg:text-5xl mb-8">{title}</h1>
            <div className="flex flex-row flex-wrap">
              <div className="flex-0">
                <Link to={`/about-us/${author.slug}`}>
                  <GatsbyImage image={getImage(author.image)}
                    alt={author.title}
                    className="rounded-[50%] w-[56px] h-[56px]" />
                </Link>
              </div>
              <div className="flex-1 flex flex-col justify-center mx-4">
                <p className="font-bold text-lg">{author.first_name} {author.last_name}</p>
                <p>{moment(post_date).format('MMM DD, YYYY')} <span className="text-slate-400">| {duration} min read</span></p>
              </div>
              <div className="flex-0 flex items-center w-full md:w-auto mt-4 md:mt-0">
                <BlogSocials siteUrl={siteUrl} 
                  slug={pageContext.data.slug} />
              </div>
              <div className="relative w-full h-0 pb-[60%] rounded-3xl overflow-hidden mb-2.5 mt-8">
                <img src={cover_image}
                  className="max-w-none h-full w-auto absolute top-[-9999px] left-[-9999px] right-[-9999px] bottom-[-9999px] m-auto group-hover:scale-105 transition-transform duration-300"
                  alt={title} />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="blogSidebar hidden md:block"> 
                <BlogSocials siteUrl={siteUrl} 
                  slug={pageContext.data.slug}
                  vertical />
              </div>
              <div className={clsx("blogContent", colourResolver(wordcount % 3, true))}>
                {content.map((slice, k) => (
                  <SliceRenderer key={k} slice={slice} />
                ))}
              </div>
            </div>
          </article>
        </div>
      </section>
      <section className="py-12 bg-[#f8f8f8]">
        <div className="max-w-[768px] mx-auto px-6">
          <div className="flex flex-col md:flex-row text-center md:text-left">
            <div className="flex-0">
                <Link to={`/about-us/${author.slug}`}>
                  <GatsbyImage image={author.image}
                    alt={author.title}
                    className="rounded-[50%] max-w-[200px] max-h-[200px]" />
                </Link>
              </div>
              <div className="flex-1 flex flex-col justify-center md:ml-8 mt-8 md:mt-0">
                <p className="text-xl mb-1 hidden md:block">Written by</p>
                <p className="font-black text-3xl mb-2">{author.first_name} {author.last_name}</p>
                <p className="font-bold text-lg mb-1">{author.title}</p>
                <p className="text-ellipsis">{author.bio.trim().split(/\s+/).slice(0, 38).join(' ').concat('...')}</p>
              </div>
          </div>
        </div>
      </section><section className="py-12 bg-white">
        <div className="xl:container mx-auto px-6">
          <p className="font-black text-3xl lg:text-4xl text-black mb-6">You might also like</p>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-12">
            {relatedPosts.map((i,k) => (
              <Link to={`/blog/${i.slug}`}
                className="mb-8"
                key={k}>
                <div className="relative w-full h-0 pb-[60%] rounded-3xl overflow-hidden mb-2.5">
                  <img src={i.cover_image}
                    className="max-w-none h-full w-auto absolute top-[-9999px] left-[-9999px] right-[-9999px] bottom-[-9999px] m-auto group-hover:scale-105 transition-transform duration-300"
                    alt={i.title} />
                </div>
                <p className="text-sm text-slate-500 mb-2">Posted on {moment(i.post_date).format('MMM DD, YYYY')}</p>
                <p className="font-black text-black text-xl lg:text-2xl mb-4">{i.title}</p>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </main>
  )
}

export default BlogTemplate;

export function Head({pageContext}) {
  let wordcount = 0;
  pageContext.data.content.forEach(
    (slice) =>
      slice.slice_type === 'block' &&
      (wordcount += getWordCount(prismicH.asText(slice.items[0].content)))
  )
  const duration = Math.ceil(wordcount / 225);
  const author = `${pageContext.data.author.first_name} ${pageContext.data.author.last_name}`
  return (
    <Seo title={`${pageContext.data.title} | Sourci`}
      description={pageContext.data.description}
      pageImage={pageContext.data.cover_image}
      duration={duration}
      author={author}
      canonical={`/blog/${pageContext.data.slug}`} />
  )
}